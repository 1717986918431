jQuery( document ).ready(function($) {

    //Get top back button
  var back_to_top = document.getElementById("back_to_top");

  // When the user scrolls down 20px from the top of the document, show the button
    window.onscroll = function() {scrollFunction()};
  
    function scrollFunction() {
      if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        back_to_top.style.display = "block";
      } else {
        back_to_top.style.display = "none";
      }
    }
  
  // When the user clicks on the button, scroll to the top of the document
    jQuery('#back_to_top').click(function() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }); 

    jQuery('.block-testimonial-carousel .client-info').owlCarousel({
        nav:true,
        loop:true,
        dots: true,
        autoplay:false,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        responsiveClass:true,
        responsive:{
            0:{
                items:1
            },
            600:{
                items:1
            },
            700:{
                items:2
            },
            1100:{
                items:3
            }
        }
    });
    jQuery('.image-carousel').owlCarousel({
        loop:true,
        dots: true,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        responsiveClass:true,
        items:1,
        
        responsive:{
            600:{
                stagePadding: 100
            },
            990:{
                stagePadding: 200
            },
            1100:{
                stagePadding: 300
            }
        }

    });
	
	jQuery('.gallery-carousel').owlCarousel({
        loop:true,
        dots: true,
        autoplay:true,
        autoplayTimeout:3000,
        autoplayHoverPause:true,
        responsiveClass:true,
        items:4,
        
        responsive:{
            600:{
                stagePadding: 100
            },
            990:{
                stagePadding: 200
            },
            1100:{
                stagePadding: 300
            }
        }

    });

    $( ".site-header" ).wrap( "<div class='header-section'></div>" );

    $(window).scroll(function(){
        if ($(window).scrollTop() >= 100) {
            $('.header-section').addClass('fixed-header');
        }
        else {
            $('.header-section').removeClass('fixed-header');
        }
    });

    // Add Overlay
    function overlayAdd(){
        var overlay = document.getElementById('video-overlay'),
        video= document.getElementById('video'),
        videoPlaying = false;    
        function hideOverlay() {
        overlay.style.display = "none";
        videoPlaying = true;
        video.play();
        }
        function showOverlay() {
        // this check is to differentiate seek and actual pause 
            if (video.readyState === 4) {
                overlay.style.display = "block";
                videoPlaying = true;
            }
        }
        video.addEventListener('pause', showOverlay);
        overlay.addEventListener('click', hideOverlay);
    }
    overlayAdd();
});

// conunter number function 
jQuery(document).ready(function ($) {
    $(function() {
        function count_number(){
            $('.counting').each(function () {
                var $this = $(this);
                jQuery({ Counter: 0 }).animate({ Counter: $this.text() }, {
                  duration: 5000,
                  easing: 'swing',
                  step: function () {
                    $this.text(Math.ceil(this.Counter));
                  }
                });
              });
        }
        count_number();
        $('.counter .val_end').hide();
        var counter_div = $(".counter-section").offset().top;
        var counter_start = counter_div -500;
        var counter_stop = 0;
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();
            if (counter_stop == 0 && scroll >=  counter_start ) {
                $('.counter .val_start').hide();
                $('.counter .val_end').show();
                $('.counter .val_end').addClass('counting');
                count_number();
                counter_stop = 1;                
            }
        });
    });
});
